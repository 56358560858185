<template>
  <div class="c_free_apply">
    <div class="stu-module-header">
      <div class="stu-module-title">
        免做申请（提交时间：{{ writeTime || "--" }}）
      </div>
      <div>
        <!-- 毕业设计添加 -->
        <el-button v-if="designType && canCommit" @click="designAdd" type="primary"
          >添加</el-button
        >
        <!-- 毕业大作业 提交 -->
        <el-button v-if="workSubmit" @click="openHomeworkAdd" type="primary"
          >提交</el-button
        >
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      :max-height="500"
      :height="500"
      ref="tableRef"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="提交时间"
        align="center"
        min-width="140px"
        show-overflow-tooltip
        :formatter="createTimeFM"
      >
      </el-table-column>
      <el-table-column
        prop="stuffInfo"
        label="审核评语"
        align="center"
        min-width="220px"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="paperStatusS"
        label="审核状态"
        align="center"
        min-width="100px"
      >
        <template slot-scope="scope">
          <span>{{
            designType
              ? designStatus(scope.row.checkStatus)
              : scope.row.paperStatusS
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="100px">
        <template slot-scope="scope">
          <el-button type="primary" @click="detail(scope.row)">详情</el-button>
          <el-button type="primary" @click="revoke(scope.row)">撤销</el-button>
          <!-- <el-button
            type="primary"
            v-if="!designType"
            @click="download(scope.row)"
            >下载</el-button
          > -->
        </template>
      </el-table-column>
      <!-- <el-table-column prop="state" label="状态" align="center" width="90px">
        <template slot-scope="scope">
          <span :style="{ color: '#FF9B22' }">{{ scope.row.state }}</span>
        </template>
      </el-table-column> -->
    </el-table>
    <!-- <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination> -->
    <el-dialog
      :visible.sync="dialog"
      :title="`${
        designType ? '免做申请详情' : dialogType === 1 ? '提交审核' : '审核详情'
      }`"
      width="30%"
    >
      <ul class="writeBigHomework_detail">
        <template v-if="dialogType === 0">
          <li v-if="selectRow.createTime">
            <span>提交时间：</span>
            <span>{{ selectRow.createTime }}</span>
          </li>
          <li v-if="selectRow.updateTime || selectRow.checkTime">
            <span>审核时间：</span>
            <span>{{
              selectRow.updateTime ? selectRow.updateTime : selectRow.checkTime
            }}</span>
          </li>
          <li v-if="selectRow.attachmentUrl">
            <span>提交论文地址：</span>
            <a :href="selectRow.attachmentUrl" v-if="selectRow.attachmentUrl">
              查看
            </a>
          </li>
          <li v-if="selectRow.paperStatusS">
            <span>审核状态：</span>
            <span>{{ selectRow.paperStatusS }}</span>
          </li>
          <li v-else-if="selectRow.checkStatus || selectRow.checkStatus === 0">
            <span>审核状态：</span>
            <!-- 0等待审核 1审核通过 2审核驳回 -->
            <span>{{
              selectRow.checkStatus === 0
                ? "等待审核"
                : selectRow.checkStatus === 1
                ? "审核通过"
                : selectRow.checkStatus === 2
                ? "审核驳回"
                : ""
            }}</span>
          </li>
          <li v-if="selectRow.rejectReason">
            <span>审核意见：</span>
            <span>{{ selectRow.rejectReason }}</span>
          </li>
          <li v-if="selectRow.stuffInfo">
            <span>审核评语：</span>
            <span>{{ selectRow.stuffInfo }}</span>
          </li>
          <li v-if="selectRow.stuffUrl">
            <span>审核附件地址：</span>
            <a :href="selectRow.stuffUrl"> 查看 </a>
          </li>
          <li>
            <span>分数：</span>
            <span v-if="selectRow.score==null||selectRow.score==''">暂无成绩</span>
            <span v-else>{{ selectRow.score }}</span>
          </li>
        </template>

        <li v-if="dialogType === 1 || designType">
          <el-upload
            class="upload-demo"
            ref="upload"
            :action="`${url}hnjxjy-core/eduOnlineQa/addEduGraduateWorkStudentById`"
            :data="{
              applyFree: 1,
              courseId: course.id,
            }"
            :headers="{
              'x-token': auth['x-token'],
              'x-perm': auth['x-perm'],
            }"
            :on-change="onChange"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="false"
          >

          </el-upload>
        </li>
      </ul>
<!--      <span slot="footer" class="dialog-footer">-->
<!--        &lt;!&ndash; 添加的时候 显示提交按钮 或者 毕业设计的时候显示 &ndash;&gt;-->
<!--        <el-button-->
<!--          type="primary"-->
<!--          v-if="dialogType === 1 || designType"-->
<!--          @click="submit"-->
<!--          >确 定</el-button-->
<!--        >-->
<!--      </span>-->
    </el-dialog>
    <!-- 毕业设计 添加 -->
    <el-dialog :visible.sync="addDialog" title="添加" width="30%">
      <el-form inline>
        <el-form-item label="选择文件">
          <el-upload
            class="upload-demo"
            ref="addUpload"
            :action="`${url}hnjxjy-core/eduGraduateDesignStu/addEduGraduateDesignStudentById`"
            :data="{
              courseId: course.id,
              applyFree: 1
            }"
            :headers="{
              'x-token': auth['x-token'],
              'x-perm': auth['x-perm'],
            }"
            :on-change="onAddChange"
            :on-success="handleAddSuccess"
            :on-error="handleAddError"
            :on-remove="handleAddRemove"
            :file-list="fileAddList"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary">
              选取文件
            </el-button>
            <p style="color: red;font-size: 14px; display: inline-block; margin-left: 10px; font-weight: bold;">仅支持office文件格式</p>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="addLoading" @click="onDesignAdd"
          >添加</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  QueryBatchByStudentId,
  QueryEduGraduateWorkByStudentId,
  DownloadByStuff,
  QueryEduGraduateWorkById,
  QueryEduScoreFreeByStudentId, // 毕业设计 免做申请 - old
  QueryEduGraduateDesignByStudentId, // 毕业设计 免做申请
  SelectEduScoreFreeById, // 毕业设计 详情
} from "@/libs/api/topic";

import { BASE_URL } from "@/libs/domain";
import { dateTimeFormat, dateFormatter } from "@/tools/date";
export default {
  name: "c_free_apply",
  data() {
    return {
      loading: false,

      tableData: [],
      pageSize: 10,
      current: 1,
      total: 0,
      writeTime: null,
      selectRow: {}, // 审核详情
      dialog: false,
      dialogType: 0, // 添加1 查看0
      workSubmit: false, // 提交按钮是否显示 0显示 1不显示
      fileList: [],

      addDialog: false,
      addLoading: false,
      fileAddList: [],
      canCommit:false
    };
  },
  computed: {
    // datalist() {
    //   return this.tableData.slice(
    //     (this.current - 1) * this.pageSize,
    //     this.current * this.pageSize
    //   );
    // },
    url() {
      return BASE_URL;
    },
    course() {
      return this.$store.getters.getStuMyCourse;
    },
    auth() {
      return this.$store.getters.getAuth;
    },
    user() {
      return this.$store.getters.getUser;
    },
    designType() {
      // 毕业设计
      return this.course && this.course.courseType === 1;
    },
    designStatus() {
      return (s) => {
        if (s === 0) return "待审核";
        if (s === 1) return "审核通过";
        if (s === 2) return "驳回";
        return s;
      };
    },
  },
  mounted() {
    if (this.designType) {
      this.designInit();
    } else {
      this.init();
    }
  },
  methods: {
    init() {
      this.loading = true;
      QueryEduGraduateWorkByStudentId({ applyFree: 1 })
        .then((res) => {
          this.writeTime =
            res.data.writeStartTime + "-" + res.data.writeEndTime;
          this.tableData = res.data.eduGraduateWorkStudents;
          this.canCommit = res.data.workSubmit == 0;
          this.workSubmit = res.data.workSubmit === 0 ? true : false;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 毕业设计 免做申请 查询
    designInit() {
      this.loading = true;
      // QueryEduScoreFreeByStudentId({
      //   courseId: this.course.id,
      // })
      QueryEduGraduateDesignByStudentId({ applyFree: 1 })
        .then((res) => {
          this.getSubTime();
          // this.tableData = res.data.list;
          // this.total = res.data.total;
          this.tableData = res.data.eduGraduateDesignStudents;
          this.workSubmit = res.data.workSubmit === 0 ? true : false;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getSubTime() {
      QueryBatchByStudentId().then((res) => {
        this.writeTime = `${res.data.freeStartTime} - ${res.data.freeEndTime}`;
      });
    },
    download(row) {
      if (row) {
        DownloadByStuff({ id: row.id }).then((res) => {
          // let blob = new Blob([res]);
          let el = document.createElement("a");
          let href = window.URL.createObjectURL(res);
          el.href = href;
          // el.download = `撰写大作业`;
          document.body.appendChild(el);
          el.click();
          document.body.removeChild(el);
          window.URL.revokeObjectURL(href);
        });
      }
    },
    // 点击行查看
    detail(row) {
      if(this.course.courseName.indexOf("毕业设计") >= 0){
        if (row) {
          this.dialogType = 0;
          this.dialog = true;
          if (this.designType) {
            this.designDetail(row);
            // this.designDetail(row.id);
          }
          SelectEduScoreFreeById({ id: row.id }).then((res) => {
            if (res.data !== undefined){
              this.selectRow = res.data;
              if (this.selectRow.createTime) {
                this.selectRow.createTime = dateTimeFormat(
                    this.selectRow.createTime
                );
              }
              if (this.selectRow.updateTime) {
                this.selectRow.updateTime = dateTimeFormat(
                    this.selectRow.updateTime
                );
              }
            }
          });
        }
      }else if (this.course.courseName.indexOf("毕业大作业") >= 0){
        if (row) {
          this.dialogType = 0;
          this.dialog = true;
          if (this.designType) {
            this.designDetail(row);
            // this.designDetail(row.id);
          }
          QueryEduGraduateWorkById({ id: row.id }).then((res) => {
            if (res.data !== undefined){
              this.selectRow = res.data;
              if (this.selectRow.createTime) {
                this.selectRow.createTime = dateTimeFormat(
                    this.selectRow.createTime
                );
              }
              if (this.selectRow.updateTime) {
                this.selectRow.updateTime = dateTimeFormat(
                    this.selectRow.updateTime
                );
              }
            }
          });
        }
      }
    },
    designDetail(row) {
      this.selectRow = row;
      // SelectEduScoreFreeById({ id }).then((res) => {
      //   this.selectRow = res.data;
      //   if (this.selectRow.updateTime) {
      //     this.selectRow.updateTime = dateTimeFormat(this.selectRow.updateTime);
      //   }
      // });
    },
    onChange(file, fileList) {
      this.fileList = fileList;
    },
    handleSuccess(res) {
      this.$refs.upload.clearFiles();
      if (res.code === 2000) {
        this.dialog = false;
        this.$message.success("提交成功");
        this.init();
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    submit() {
      if (!this.fileList.length) return this.$message.error("请选择文件");
      this.$refs.upload.submit();
    },
    onAddChange(file, fileList) {
      this.fileAddList = fileList;
    },
    handleAddSuccess(res) {
      this.addLoading = false;
      this.$refs.addUpload.clearFiles();
      if (res.code === 2000) {
        this.addDialog = false;
        this.$message.success("添加成功");
        this.designInit();
      }else {
        this.$message.error(res.message);
      }
    },
    handleAddError(error) {
      this.addLoading = false;
    },
    handleAddRemove(file, fileList) {
      this.fileAddList = fileList;
    },
    // 毕业设计添加
    onDesignAdd() {
      if (!this.fileAddList.length) return this.$message.error("请选择文件");
      this.addLoading = true;
      this.$refs.addUpload.submit();
    },
    // 添加
    designAdd() {
      this.addDialog = true;
    },
    // 毕业大作业 提交审核按钮
    openHomeworkAdd() {
      // alert("1212222")
      this.dialogType = 1;//2022/4/24修改本行代码 修复提交免试弹窗错误问题
      // this.dialog = true;
      this.addDialog = true;
    },
    createTimeFM(row) {
      if (row) {
        return row.createTime ? dateTimeFormat(row.createTime) : null;
      }
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.designType ? this.designInit() : this.init();
      }
    },
    revoke(row){
      this.openLoadingView();
      this.$axios_supermallData({
        url: "hnjxjy-core/eduOnlineQa/updateEduGraduateWorkById",
        method: 'get',
        params: {
          id: row.id
        }
      }).then(resp => {
        if (resp.data.code === 2000){
          this.$message.success("撤销成功");
        }
        this.init();
        this.loadingView.close();
      }).catch(err => {
        this.loadingView.close();
      });
    },
  },
};
</script>

<style scoped lang="less">
.c_free_apply {
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    margin-bottom: 20px;
  }
  .writeBigHomework_detail {
    li {
      margin-bottom: 15px;
      a {
        color: @mainColor;
        text-decoration: none;
      }
      span {
        font-size: 15px;
      }
      span:first-child {
        font-weight: bold;
      }
    }
  }
}
</style>
