/**
 * 处理日期的公共方法
 * @author wen
 * @time 2021/3/26
 */


/**
 * 计算两个日期相差天数
 */
 const diffDays = (startDate, endDate) => {
    const diff = new Date(endDate).getTime() / new Date(startDate).getTime();
    const result = parseInt(diff / (1000 * 60 * 60 * 24), 10);
    return result;
};
// 数字补0操作
const addZero = (num) => {
    return num < 10 ? `0${num}` : num;
};
// 格式化日期 传入date类型，返回格式:2020/10/29 17:41
const dateTimeFormat = (time) => {
    if (!time) return null;
    let t = new Date(time);
    t = `${t.getFullYear()}/${addZero(t.getMonth() + 1)}/${addZero(t.getDate())} ${addZero(t.getHours())}:${addZero(t.getMinutes())}:${addZero(t.getSeconds())}`;
    return t;
};
// 格式化日期 传入date类型，返回格式:2020/10/29
const dateFormatter = (time) => {
    if (!time) return null;
    let t = new Date(time);
    t = `${t.getFullYear()}/${addZero(t.getMonth() + 1)}/${addZero(t.getDate())}`;
    return t;
};
module.exports = {
    diffDays,
    dateTimeFormat,
    dateFormatter
};