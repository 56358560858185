import request from '../request'
import qs from 'qs';
// 毕业设计
// 开题报告-查询
const QueryEduProByStuId = (params) => {
    return request.get(`eduProposal/queryEduProByStuId?${qs.stringify(params)}`).then(res => res.data);
}
// 开题报告，提交论文和免做申请时间
const QueryBatchByStudentId = () => {
    return request.get(`eduProposal/queryBatchByStudentId`).then(res => res.data);
}
// 根据id查询开题报告详情
const QueryEduProById = (params) => {
    return request.get(`eduProposal/queryEduProById?${qs.stringify(params)}`).then(res => res.data);
}
// 提交开题报告 
const InsertSelective = (data) => {
    return request.post(`eduProposal/insertSelective`, data).then(res => res.data);
}
// 我的课程-毕业设计-指导教师
const QueryEduGraduateDesignTeacher = () => {
    return request.get(`eduCourseTopic/queryEduGraduateDesignTeacher`).then(res => res.data);
}
// 毕业大作业
// 参考选题 - 查询
const QueryEduCourseTopicByStu = (params) => {
    return request.get(`eduCourseTopic/queryEduCourseTopicByStu?${qs.stringify(params)}`).then(res => res.data);
}

// 阅读毕业设计参考题目选题详情 / 课程文件 / 查看毕业大作业选题详情
const UpdateEduCourseTopicByStu = (data) => {
    return request.post('eduCourseTopic/updateEduCourseTopicByStu', data).then(res => res.data);
}
// 毕业大作业/毕设-课程文件-查询
const QueryEduCourseTopicWorkByStu = (params) => {
    return request.get(`eduCourseTopic/queryEduCourseTopicWorkByStu?${qs.stringify(params)}`).then(res => res.data);
}
// 毕业大作业-时间安排
const QueryEduCourseBatchTime = () => {
    return request.get(`eduCourseTopic/queryEduCourseBatchTime`).then(res => res.data);
}
// 毕业设计-时间安排
const QueryEduGraduateDesignBatchTime = () => {
    return request.get(`eduCourseTopic/queryEduGraduateDesignBatchTime`).then(res => res.data);
}
// 毕业大作业/毕业设计-课程视频
const QueryCourseResourceById = (params) => {
    return request.get(`eduCourseTopic/queryCourseResourceById?${qs.stringify(params)}`).then(res => res.data);
}
// 毕业大作业/毕业设计-课程视频-资源下载
const TopicDownloadById = (params) => {
    return request({
        url: `eduCourseTopic/downloadById?${qs.stringify(params)}`,
        method: 'get',
        responseType: 'blob'
    }).then(res => res.data);
}
// 我的课程-毕业大作业-下载毕业大作业
const OnlineDownloadById = (params) => {
    return request({
        url: `eduOnlineQa/downloadById?${qs.stringify(params)}`,
        method: 'get',
        responseType: 'blob'
    }).then(res => res.data);
}
// 我的课程-毕业大作也-审核详情/免试详情
const QueryEduGraduateWorkById = (params) => {
    return request.get(`eduOnlineQa/queryEduGraduateWorkById?${qs.stringify(params)}`).then(res => res.data);
}
// 我的课程-毕业大作业-撰写大作业/免试-查询 applyFree 0撰写大作业查询 1免试查询
const QueryEduGraduateWorkByStudentId = (params) => {
    return request.get(`eduOnlineQa/queryEduGraduateWorkByStudentId?${qs.stringify(params)}`).then(res => res.data);
}
// 毕业设计 - 免试申请 查询
const QueryEduGraduateDesignByStudentId = (params) => {
    return request.get(`eduGraduateDesignStu/queryEduGraduateDesignByStudentId?${qs.stringify(params)}`).then(res => res.data)
}
// 我的课程-毕业大作业-下载审核结果附件
const DownloadByStuff = (params) => {
    return request({
        method: 'get',
        url: `eduOnlineQa/downloadByStuff?${qs.stringify(params)}`,
        responseType: 'blob'
    }).then(res => res.data);
}

// 我的课程-毕业设计-提交论文-查询
const SelectEduGraDesStuByStuId = (params) => {
    return request.get(`eduGraduateDesignStu/selectEduGraDesStuByStuId?${qs.stringify(params)}`).then(res => res.data);
}
// 我的课程-毕业设计-提交论文--根据id查询
const SelectEduGraDesStuById = (params) => {
    return request.get(`eduGraduateDesignStu/selectEduGraDesStuById?${qs.stringify(params)}`).then(res => res.data);
}
// 我的课程-毕业设计-论文终稿--查询
const FinalPaper = (params) => {
    return request.get(`eduGraduateDesignStu/finalPaper?${qs.stringify(params)}`).then(res => res.data);
}
// 我的课程-毕业设计-免做申请-查询
const QueryEduScoreFreeByStudentId = (params) => {
    return request.get(`eduScoreFree/queryEduScoreFreeByStudentId?${qs.stringify(params)}`).then(res => res.data);
}
// 我的课程-毕业设计-免做申请--详情
const SelectEduScoreFreeById = (params) => {
    return request.get(`eduGraduateDesignStu/queryEduGraduateDesignStudentById?${qs.stringify(params)}`).then(res => res.data);
}
export {
    QueryEduProByStuId,
    QueryBatchByStudentId,
    QueryEduProById,
    InsertSelective,
    QueryEduCourseTopicByStu,
    UpdateEduCourseTopicByStu,
    QueryEduCourseTopicWorkByStu,
    QueryEduCourseBatchTime,
    QueryEduGraduateDesignBatchTime,
    QueryCourseResourceById,
    TopicDownloadById,
    OnlineDownloadById,
    QueryEduGraduateWorkById,
    QueryEduGraduateWorkByStudentId,
    QueryEduGraduateDesignByStudentId,
    DownloadByStuff,
    SelectEduGraDesStuByStuId,
    SelectEduGraDesStuById,
    QueryEduGraduateDesignTeacher,
    FinalPaper,
    QueryEduScoreFreeByStudentId,
    SelectEduScoreFreeById
}